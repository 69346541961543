import validate from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/node_modules/nuxt/dist/pages/runtime/validate.js";
import cancel_45stream_45global from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/middleware/cancelStream.global.ts";
import dev_45mode_45global from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/middleware/dev-mode.global.ts";
import maintenance_45global from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/middleware/maintenance.global.ts";
export const globalMiddleware = [
  validate,
  cancel_45stream_45global,
  dev_45mode_45global,
  maintenance_45global
]
export const namedMiddleware = {
  auction: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/middleware/auction.ts"),
  connected: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/middleware/connected.ts"),
  "grid-strategy-subaccount": () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/middleware/grid-strategy-subaccount.ts"),
  leaderboard: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/middleware/leaderboard.ts"),
  markets: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/middleware/markets.ts"),
  swap: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/middleware/swap.ts")
}