import { default as indexkMOp0mAfFWMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/account/index.vue?macro=true";
import { default as positionstTELjRzbDaMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/account/positions.vue?macro=true";
import { default as account5Yl3MWboZOMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/account.vue?macro=true";
import { default as indexL8McHNCunpMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/derivatives/index.vue?macro=true";
import { default as order_45historyOteCYYKx7MMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/derivatives/order-history.vue?macro=true";
import { default as trade_45historyYUgbfpWGenMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/derivatives/trade-history.vue?macro=true";
import { default as triggers6ZetrzOTIrMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/derivatives/triggers.vue?macro=true";
import { default as derivativesYRB9ylZ25fMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/derivatives.vue?macro=true";
import { default as funding_45paymentsF89bHYN1KKMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/positions/funding-payments.vue?macro=true";
import { default as indexEcK8jJ9SdzMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/positions/index.vue?macro=true";
import { default as positionscaco8lntsVMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/positions.vue?macro=true";
import { default as indexvynl2bzpJrMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/spot/index.vue?macro=true";
import { default as order_45historyRkBDzRMOjTMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/spot/order-history.vue?macro=true";
import { default as swap_45historysLuHlLLksWMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/spot/swap-history.vue?macro=true";
import { default as trade_45historyNKWhdF8E2BMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/spot/trade-history.vue?macro=true";
import { default as spotqn9UIOwvJKMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/spot.vue?macro=true";
import { default as depositspWdKHLjoglMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/wallet-history/deposits.vue?macro=true";
import { default as indexYjZpeh3YHyMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/wallet-history/index.vue?macro=true";
import { default as withdrawalsQi9SKqOlmyMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/wallet-history/withdrawals.vue?macro=true";
import { default as wallet_45historyrxDeFrLPnkMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/wallet-history.vue?macro=true";
import { default as activityPgvXJo9EEIMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity.vue?macro=true";
import { default as bids9g3rjMU0afMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/auctions/[auction]/bids.vue?macro=true";
import { default as index9ypkQ4ENs6Meta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/auctions/[auction]/index.vue?macro=true";
import { default as _91auction_9304Np8InIfRMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/auctions/[auction].vue?macro=true";
import { default as indexzLK9ah8fJ9Meta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/auctions/index.vue?macro=true";
import { default as auctionsO33Ywx8MZHMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/auctions.vue?macro=true";
import { default as bridgeyXTpFNoDpcMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/bridge.vue?macro=true";
import { default as fee_45discountsxyRym7rwEKMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/fee-discounts.vue?macro=true";
import { default as _91futures_93l7peOXswM9Meta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/futures/[futures].vue?macro=true";
import { default as indexWzL8QOOEsFMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/index.vue?macro=true";
import { default as institutionalyRyAEBdX4PMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/institutional.vue?macro=true";
import { default as leaderboardJubZSipeQBMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/leaderboard.vue?macro=true";
import { default as maintenanceC4Bol6dAnuMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/maintenance.vue?macro=true";
import { default as _91market_93pOpKt8zWcEMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/market/[market].vue?macro=true";
import { default as marketseEIXYoKSl3Meta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/markets.vue?macro=true";
import { default as _91spot_93IQxEt31FHqMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/spot/[spot].vue?macro=true";
import { default as swapspE8DAwBQCMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/swap.vue?macro=true";
import { default as termsbRbxeNwiiQMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/terms.vue?macro=true";
import { default as trade_45and_45earnd7dGPU0RCeMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/trade-and-earn.vue?macro=true";
import { default as historyR5mRNEbCPmMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/trading-bots/grid/spot/[market]/history.vue?macro=true";
import { default as indexlQLfjQwJ0kMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/trading-bots/grid/spot/[market]/index.vue?macro=true";
import { default as _91market_93PE1JycSpLcMeta } from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/trading-bots/grid/spot/[market].vue?macro=true";
export default [
  {
    path: account5Yl3MWboZOMeta?.path ?? "/account",
    children: [
  {
    name: indexkMOp0mAfFWMeta?.name ?? "account",
    path: indexkMOp0mAfFWMeta?.path ?? "",
    meta: indexkMOp0mAfFWMeta || {},
    alias: indexkMOp0mAfFWMeta?.alias || [],
    redirect: indexkMOp0mAfFWMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: positionstTELjRzbDaMeta?.name ?? "account-positions",
    path: positionstTELjRzbDaMeta?.path ?? "positions",
    meta: positionstTELjRzbDaMeta || {},
    alias: positionstTELjRzbDaMeta?.alias || [],
    redirect: positionstTELjRzbDaMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/account/positions.vue").then(m => m.default || m)
  }
],
    name: account5Yl3MWboZOMeta?.name ?? undefined,
    meta: account5Yl3MWboZOMeta || {},
    alias: account5Yl3MWboZOMeta?.alias || [],
    redirect: account5Yl3MWboZOMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/account.vue").then(m => m.default || m)
  },
  {
    name: activityPgvXJo9EEIMeta?.name ?? "activity",
    path: activityPgvXJo9EEIMeta?.path ?? "/activity",
    children: [
  {
    path: derivativesYRB9ylZ25fMeta?.path ?? "derivatives",
    children: [
  {
    name: indexL8McHNCunpMeta?.name ?? "activity-derivatives",
    path: indexL8McHNCunpMeta?.path ?? "",
    meta: indexL8McHNCunpMeta || {},
    alias: indexL8McHNCunpMeta?.alias || [],
    redirect: indexL8McHNCunpMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/derivatives/index.vue").then(m => m.default || m)
  },
  {
    name: order_45historyOteCYYKx7MMeta?.name ?? "activity-derivatives-order-history",
    path: order_45historyOteCYYKx7MMeta?.path ?? "order-history",
    meta: order_45historyOteCYYKx7MMeta || {},
    alias: order_45historyOteCYYKx7MMeta?.alias || [],
    redirect: order_45historyOteCYYKx7MMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/derivatives/order-history.vue").then(m => m.default || m)
  },
  {
    name: trade_45historyYUgbfpWGenMeta?.name ?? "activity-derivatives-trade-history",
    path: trade_45historyYUgbfpWGenMeta?.path ?? "trade-history",
    meta: trade_45historyYUgbfpWGenMeta || {},
    alias: trade_45historyYUgbfpWGenMeta?.alias || [],
    redirect: trade_45historyYUgbfpWGenMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/derivatives/trade-history.vue").then(m => m.default || m)
  },
  {
    name: triggers6ZetrzOTIrMeta?.name ?? "activity-derivatives-triggers",
    path: triggers6ZetrzOTIrMeta?.path ?? "triggers",
    meta: triggers6ZetrzOTIrMeta || {},
    alias: triggers6ZetrzOTIrMeta?.alias || [],
    redirect: triggers6ZetrzOTIrMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/derivatives/triggers.vue").then(m => m.default || m)
  }
],
    name: derivativesYRB9ylZ25fMeta?.name ?? undefined,
    meta: derivativesYRB9ylZ25fMeta || {},
    alias: derivativesYRB9ylZ25fMeta?.alias || [],
    redirect: derivativesYRB9ylZ25fMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/derivatives.vue").then(m => m.default || m)
  },
  {
    path: positionscaco8lntsVMeta?.path ?? "positions",
    children: [
  {
    name: funding_45paymentsF89bHYN1KKMeta?.name ?? "activity-positions-funding-payments",
    path: funding_45paymentsF89bHYN1KKMeta?.path ?? "funding-payments",
    meta: funding_45paymentsF89bHYN1KKMeta || {},
    alias: funding_45paymentsF89bHYN1KKMeta?.alias || [],
    redirect: funding_45paymentsF89bHYN1KKMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/positions/funding-payments.vue").then(m => m.default || m)
  },
  {
    name: indexEcK8jJ9SdzMeta?.name ?? "activity-positions",
    path: indexEcK8jJ9SdzMeta?.path ?? "",
    meta: indexEcK8jJ9SdzMeta || {},
    alias: indexEcK8jJ9SdzMeta?.alias || [],
    redirect: indexEcK8jJ9SdzMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/positions/index.vue").then(m => m.default || m)
  }
],
    name: positionscaco8lntsVMeta?.name ?? undefined,
    meta: positionscaco8lntsVMeta || {},
    alias: positionscaco8lntsVMeta?.alias || [],
    redirect: positionscaco8lntsVMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/positions.vue").then(m => m.default || m)
  },
  {
    path: spotqn9UIOwvJKMeta?.path ?? "spot",
    children: [
  {
    name: indexvynl2bzpJrMeta?.name ?? "activity-spot",
    path: indexvynl2bzpJrMeta?.path ?? "",
    meta: indexvynl2bzpJrMeta || {},
    alias: indexvynl2bzpJrMeta?.alias || [],
    redirect: indexvynl2bzpJrMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/spot/index.vue").then(m => m.default || m)
  },
  {
    name: order_45historyRkBDzRMOjTMeta?.name ?? "activity-spot-order-history",
    path: order_45historyRkBDzRMOjTMeta?.path ?? "order-history",
    meta: order_45historyRkBDzRMOjTMeta || {},
    alias: order_45historyRkBDzRMOjTMeta?.alias || [],
    redirect: order_45historyRkBDzRMOjTMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/spot/order-history.vue").then(m => m.default || m)
  },
  {
    name: swap_45historysLuHlLLksWMeta?.name ?? "activity-spot-swap-history",
    path: swap_45historysLuHlLLksWMeta?.path ?? "swap-history",
    meta: swap_45historysLuHlLLksWMeta || {},
    alias: swap_45historysLuHlLLksWMeta?.alias || [],
    redirect: swap_45historysLuHlLLksWMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/spot/swap-history.vue").then(m => m.default || m)
  },
  {
    name: trade_45historyNKWhdF8E2BMeta?.name ?? "activity-spot-trade-history",
    path: trade_45historyNKWhdF8E2BMeta?.path ?? "trade-history",
    meta: trade_45historyNKWhdF8E2BMeta || {},
    alias: trade_45historyNKWhdF8E2BMeta?.alias || [],
    redirect: trade_45historyNKWhdF8E2BMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/spot/trade-history.vue").then(m => m.default || m)
  }
],
    name: spotqn9UIOwvJKMeta?.name ?? undefined,
    meta: spotqn9UIOwvJKMeta || {},
    alias: spotqn9UIOwvJKMeta?.alias || [],
    redirect: spotqn9UIOwvJKMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/spot.vue").then(m => m.default || m)
  },
  {
    path: wallet_45historyrxDeFrLPnkMeta?.path ?? "wallet-history",
    children: [
  {
    name: depositspWdKHLjoglMeta?.name ?? "activity-wallet-history-deposits",
    path: depositspWdKHLjoglMeta?.path ?? "deposits",
    meta: depositspWdKHLjoglMeta || {},
    alias: depositspWdKHLjoglMeta?.alias || [],
    redirect: depositspWdKHLjoglMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/wallet-history/deposits.vue").then(m => m.default || m)
  },
  {
    name: indexYjZpeh3YHyMeta?.name ?? "activity-wallet-history",
    path: indexYjZpeh3YHyMeta?.path ?? "",
    meta: indexYjZpeh3YHyMeta || {},
    alias: indexYjZpeh3YHyMeta?.alias || [],
    redirect: indexYjZpeh3YHyMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/wallet-history/index.vue").then(m => m.default || m)
  },
  {
    name: withdrawalsQi9SKqOlmyMeta?.name ?? "activity-wallet-history-withdrawals",
    path: withdrawalsQi9SKqOlmyMeta?.path ?? "withdrawals",
    meta: withdrawalsQi9SKqOlmyMeta || {},
    alias: withdrawalsQi9SKqOlmyMeta?.alias || [],
    redirect: withdrawalsQi9SKqOlmyMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/wallet-history/withdrawals.vue").then(m => m.default || m)
  }
],
    name: wallet_45historyrxDeFrLPnkMeta?.name ?? undefined,
    meta: wallet_45historyrxDeFrLPnkMeta || {},
    alias: wallet_45historyrxDeFrLPnkMeta?.alias || [],
    redirect: wallet_45historyrxDeFrLPnkMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity/wallet-history.vue").then(m => m.default || m)
  }
],
    meta: activityPgvXJo9EEIMeta || {},
    alias: activityPgvXJo9EEIMeta?.alias || [],
    redirect: activityPgvXJo9EEIMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/activity.vue").then(m => m.default || m)
  },
  {
    path: auctionsO33Ywx8MZHMeta?.path ?? "/auctions",
    children: [
  {
    path: _91auction_9304Np8InIfRMeta?.path ?? ":auction()",
    children: [
  {
    name: bids9g3rjMU0afMeta?.name ?? "auctions-auction-bids",
    path: bids9g3rjMU0afMeta?.path ?? "bids",
    meta: bids9g3rjMU0afMeta || {},
    alias: bids9g3rjMU0afMeta?.alias || [],
    redirect: bids9g3rjMU0afMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/auctions/[auction]/bids.vue").then(m => m.default || m)
  },
  {
    name: index9ypkQ4ENs6Meta?.name ?? "auctions-auction",
    path: index9ypkQ4ENs6Meta?.path ?? "",
    meta: index9ypkQ4ENs6Meta || {},
    alias: index9ypkQ4ENs6Meta?.alias || [],
    redirect: index9ypkQ4ENs6Meta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/auctions/[auction]/index.vue").then(m => m.default || m)
  }
],
    name: _91auction_9304Np8InIfRMeta?.name ?? undefined,
    meta: _91auction_9304Np8InIfRMeta || {},
    alias: _91auction_9304Np8InIfRMeta?.alias || [],
    redirect: _91auction_9304Np8InIfRMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/auctions/[auction].vue").then(m => m.default || m)
  },
  {
    name: indexzLK9ah8fJ9Meta?.name ?? "auctions",
    path: indexzLK9ah8fJ9Meta?.path ?? "",
    meta: indexzLK9ah8fJ9Meta || {},
    alias: indexzLK9ah8fJ9Meta?.alias || [],
    redirect: indexzLK9ah8fJ9Meta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/auctions/index.vue").then(m => m.default || m)
  }
],
    name: auctionsO33Ywx8MZHMeta?.name ?? undefined,
    meta: auctionsO33Ywx8MZHMeta || {},
    alias: auctionsO33Ywx8MZHMeta?.alias || [],
    redirect: auctionsO33Ywx8MZHMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/auctions.vue").then(m => m.default || m)
  },
  {
    name: bridgeyXTpFNoDpcMeta?.name ?? "bridge",
    path: bridgeyXTpFNoDpcMeta?.path ?? "/bridge",
    meta: bridgeyXTpFNoDpcMeta || {},
    alias: bridgeyXTpFNoDpcMeta?.alias || [],
    redirect: bridgeyXTpFNoDpcMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/bridge.vue").then(m => m.default || m)
  },
  {
    name: fee_45discountsxyRym7rwEKMeta?.name ?? "fee-discounts",
    path: fee_45discountsxyRym7rwEKMeta?.path ?? "/fee-discounts",
    meta: fee_45discountsxyRym7rwEKMeta || {},
    alias: fee_45discountsxyRym7rwEKMeta?.alias || [],
    redirect: fee_45discountsxyRym7rwEKMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/fee-discounts.vue").then(m => m.default || m)
  },
  {
    name: _91futures_93l7peOXswM9Meta?.name ?? "futures-futures",
    path: _91futures_93l7peOXswM9Meta?.path ?? "/futures/:futures()",
    meta: _91futures_93l7peOXswM9Meta || {},
    alias: _91futures_93l7peOXswM9Meta?.alias || [],
    redirect: _91futures_93l7peOXswM9Meta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/futures/[futures].vue").then(m => m.default || m)
  },
  {
    name: indexWzL8QOOEsFMeta?.name ?? "index",
    path: indexWzL8QOOEsFMeta?.path ?? "/",
    meta: indexWzL8QOOEsFMeta || {},
    alias: indexWzL8QOOEsFMeta?.alias || [],
    redirect: indexWzL8QOOEsFMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/index.vue").then(m => m.default || m)
  },
  {
    name: institutionalyRyAEBdX4PMeta?.name ?? "institutional",
    path: institutionalyRyAEBdX4PMeta?.path ?? "/institutional",
    meta: institutionalyRyAEBdX4PMeta || {},
    alias: institutionalyRyAEBdX4PMeta?.alias || [],
    redirect: institutionalyRyAEBdX4PMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/institutional.vue").then(m => m.default || m)
  },
  {
    name: leaderboardJubZSipeQBMeta?.name ?? "leaderboard",
    path: leaderboardJubZSipeQBMeta?.path ?? "/leaderboard",
    meta: leaderboardJubZSipeQBMeta || {},
    alias: leaderboardJubZSipeQBMeta?.alias || [],
    redirect: leaderboardJubZSipeQBMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/leaderboard.vue").then(m => m.default || m)
  },
  {
    name: maintenanceC4Bol6dAnuMeta?.name ?? "maintenance",
    path: maintenanceC4Bol6dAnuMeta?.path ?? "/maintenance",
    meta: maintenanceC4Bol6dAnuMeta || {},
    alias: maintenanceC4Bol6dAnuMeta?.alias || [],
    redirect: maintenanceC4Bol6dAnuMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91market_93pOpKt8zWcEMeta?.name ?? "market-market",
    path: _91market_93pOpKt8zWcEMeta?.path ?? "/market/:market()",
    meta: _91market_93pOpKt8zWcEMeta || {},
    alias: _91market_93pOpKt8zWcEMeta?.alias || [],
    redirect: _91market_93pOpKt8zWcEMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/market/[market].vue").then(m => m.default || m)
  },
  {
    name: marketseEIXYoKSl3Meta?.name ?? "markets",
    path: marketseEIXYoKSl3Meta?.path ?? "/markets",
    meta: marketseEIXYoKSl3Meta || {},
    alias: marketseEIXYoKSl3Meta?.alias || [],
    redirect: marketseEIXYoKSl3Meta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/markets.vue").then(m => m.default || m)
  },
  {
    name: _91spot_93IQxEt31FHqMeta?.name ?? "spot-spot",
    path: _91spot_93IQxEt31FHqMeta?.path ?? "/spot/:spot()",
    meta: _91spot_93IQxEt31FHqMeta || {},
    alias: _91spot_93IQxEt31FHqMeta?.alias || [],
    redirect: _91spot_93IQxEt31FHqMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/spot/[spot].vue").then(m => m.default || m)
  },
  {
    name: swapspE8DAwBQCMeta?.name ?? "swap",
    path: swapspE8DAwBQCMeta?.path ?? "/swap",
    meta: swapspE8DAwBQCMeta || {},
    alias: swapspE8DAwBQCMeta?.alias || [],
    redirect: swapspE8DAwBQCMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/swap.vue").then(m => m.default || m)
  },
  {
    name: termsbRbxeNwiiQMeta?.name ?? "terms",
    path: termsbRbxeNwiiQMeta?.path ?? "/terms",
    meta: termsbRbxeNwiiQMeta || {},
    alias: termsbRbxeNwiiQMeta?.alias || [],
    redirect: termsbRbxeNwiiQMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: trade_45and_45earnd7dGPU0RCeMeta?.name ?? "trade-and-earn",
    path: trade_45and_45earnd7dGPU0RCeMeta?.path ?? "/trade-and-earn",
    meta: trade_45and_45earnd7dGPU0RCeMeta || {},
    alias: trade_45and_45earnd7dGPU0RCeMeta?.alias || [],
    redirect: trade_45and_45earnd7dGPU0RCeMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/trade-and-earn.vue").then(m => m.default || m)
  },
  {
    path: _91market_93PE1JycSpLcMeta?.path ?? "/trading-bots/grid/spot/:market()",
    children: [
  {
    name: historyR5mRNEbCPmMeta?.name ?? "trading-bots-grid-spot-market-history",
    path: historyR5mRNEbCPmMeta?.path ?? "history",
    meta: historyR5mRNEbCPmMeta || {},
    alias: historyR5mRNEbCPmMeta?.alias || [],
    redirect: historyR5mRNEbCPmMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/trading-bots/grid/spot/[market]/history.vue").then(m => m.default || m)
  },
  {
    name: indexlQLfjQwJ0kMeta?.name ?? "trading-bots-grid-spot-market",
    path: indexlQLfjQwJ0kMeta?.path ?? "",
    meta: indexlQLfjQwJ0kMeta || {},
    alias: indexlQLfjQwJ0kMeta?.alias || [],
    redirect: indexlQLfjQwJ0kMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/trading-bots/grid/spot/[market]/index.vue").then(m => m.default || m)
  }
],
    name: _91market_93PE1JycSpLcMeta?.name ?? undefined,
    meta: _91market_93PE1JycSpLcMeta || {},
    alias: _91market_93PE1JycSpLcMeta?.alias || [],
    redirect: _91market_93PE1JycSpLcMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/trading-bots/grid/spot/[market].vue").then(m => m.default || m)
  },
  {
    name: _91futures_93l7peOXswM9Meta?.name ?? "futures",
    path: _91futures_93l7peOXswM9Meta?.path ?? "/futures",
    meta: _91futures_93l7peOXswM9Meta || {},
    alias: _91futures_93l7peOXswM9Meta?.alias || [],
    redirect: _91futures_93l7peOXswM9Meta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/futures/[futures].vue").then(m => m.default || m)
  },
  {
    name: _91spot_93IQxEt31FHqMeta?.name ?? "spot",
    path: _91spot_93IQxEt31FHqMeta?.path ?? "/spot",
    meta: _91spot_93IQxEt31FHqMeta || {},
    alias: _91spot_93IQxEt31FHqMeta?.alias || [],
    redirect: _91spot_93IQxEt31FHqMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/spot/[spot].vue").then(m => m.default || m)
  },
  {
    name: _91futures_93l7peOXswM9Meta?.name ?? "binary-options-binaryOption",
    path: _91futures_93l7peOXswM9Meta?.path ?? "/binary-options/:binaryOption",
    meta: _91futures_93l7peOXswM9Meta || {},
    alias: _91futures_93l7peOXswM9Meta?.alias || [],
    redirect: _91futures_93l7peOXswM9Meta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/futures/[futures].vue").then(m => m.default || m)
  },
  {
    name: _91futures_93l7peOXswM9Meta?.name ?? "derivative-derivative",
    path: _91futures_93l7peOXswM9Meta?.path ?? "/derivative/:derivative",
    meta: _91futures_93l7peOXswM9Meta || {},
    alias: _91futures_93l7peOXswM9Meta?.alias || [],
    redirect: _91futures_93l7peOXswM9Meta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/futures/[futures].vue").then(m => m.default || m)
  },
  {
    name: _91futures_93l7peOXswM9Meta?.name ?? "perpetual-perpetual",
    path: _91futures_93l7peOXswM9Meta?.path ?? "/perpetual/:perpetual",
    meta: _91futures_93l7peOXswM9Meta || {},
    alias: _91futures_93l7peOXswM9Meta?.alias || [],
    redirect: _91futures_93l7peOXswM9Meta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/pages/futures/[futures].vue").then(m => m.default || m)
  }
]