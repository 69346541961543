import revive_payload_client_4sVQNw7RlN from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import buffer_bieIDf9x4O from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/nuxt-config/buffer.ts";
import amplitude_qcoZpE5aTU from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/plugins/amplitude.ts";
import gtag_RbOvmu12fv from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/plugins/gtag.ts";
import handlers_oZWLY9pUCB from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/plugins/handlers.ts";
import hotjar_pBWYs5JQbk from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/plugins/hotjar.ts";
import localstorage_Ek29vdFAiI from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/plugins/localstorage.ts";
import plyr_m1vubbxwTm from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/plugins/plyr.ts";
import store_9xNuDHGAVU from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/plugins/store.ts";
import validation_30a3L3OexF from "/Users/kevin/Coding-Projects/Injective-Relayer/base-dex/versions/2023_11_18/wavely/plugins/validation.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  buffer_bieIDf9x4O,
  amplitude_qcoZpE5aTU,
  gtag_RbOvmu12fv,
  handlers_oZWLY9pUCB,
  hotjar_pBWYs5JQbk,
  localstorage_Ek29vdFAiI,
  plyr_m1vubbxwTm,
  store_9xNuDHGAVU,
  validation_30a3L3OexF
]